import { useRef, useState } from "react";

const Content = (props) => {
  const bgRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onLoadBg = () => {
    if (bgRef?.current?.width) {
      setWidth(bgRef.current.width);
    }
    if (bgRef?.current?.height) {
      setHeight(bgRef.current.height);
    }
  };
  return (
    <div
      className={`relative bg-black text-white overflow-hidden w-full h-full`}
    >
      {/* <span className="absolute z-40 top-0 right-0 bg-black text-white text-2xl font-bold">
        {width} : {height}
      </span> */}
      
      <div className="absolute z-10 top-0 left-0 flex justify-center items-center w-full h-full">
        <img
          ref={bgRef}
          src={props.imgBg}
          className="object-center w-auto h-full object-contain"
          alt=""
          onLoad={() => onLoadBg()}
        />
      </div>
      {width !== 0 && height !== 0 && (
        <div
          className="overflow-hidden relative mx-auto bg-transparent z-20"
          style={{ width: width, height: height }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Content;
