import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Report() {
  const [thead, setThead] = useState([]);
  const [tbody, setTbody] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [type, setType] = useState("detail");

  const [totEmailTerkirim, setTotEmailTerkirim] = useState(0);
  const [totEmailGagal, setTotEmailGagal] = useState(0);
  const [totInputBiodata, setTotInputBiodata] = useState(0);

  const formatJustDate = (date) => {
    var d = new Date(date);
    var strDate = ("0" + d.getDate()).slice(-2);
    var strMonth = ("0" + (d.getMonth() + 1)).slice(-2);
    var strYear = d.getFullYear();

    var formattedDate = `${strYear}-${strMonth}-${strDate}`;
    return formattedDate;
  };

  const [dateStart, setDateStart] = useState(formatJustDate(new Date()));
  const [dateEnd, setDateEnd] = useState(formatJustDate(new Date()));

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getDataDetail();
  };

  const getDataDetail = () => {
    setLoading(true);
    let param = `?date_start=${dateStart}&date_end=${dateEnd}`;

    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event" + param, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.data.length === 0) {
            setIsEmpty(true);
            setThead([]);
            setTbody([]);
          } else {
            setThead(Object.keys(response.data.data[0]));
            setTbody(response.data.data);
            setIsEmpty(false);

            //total email terkirim
            let tEmailTerkirim = response.data.data.filter(
              ({ status }) => status === "EMAIL_TERKIRIM"
            );
            setTotEmailTerkirim(tEmailTerkirim.length);

            //total email gagal
            let tEmailGagal = response.data.data.filter(
              ({ status }) => status === "EMAIL_GAGAL_TERKIRIM"
            );
            setTotEmailGagal(tEmailGagal.length);

            //total email gagal
            let tInputBiodata = response.data.data.filter(
              ({ status }) => status === "INPUT_BIODATA"
            );
            setTotInputBiodata(tInputBiodata.length);
          }

          setLoading(false);
          //console.log(response.data.data);
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {}
  };

  const handleDownload = () => {
    if (type == "detail") {
      downloadCsvDetail();
    }
  };

  const downloadCsvDetail = () => {
    if (loadingDownload) return;

    setLoadingDownload(true);
    let param = `?date_start=${dateStart}&date_end=${dateEnd}`;

    try {
      axios
        .get(
          process.env.REACT_APP_API_URL + "event/downloadCsvDetail" + param,
          {
            headers: {
              "Content-Type": "text/csv",
            },
          }
        )
        .then((response) => {
          if (response?.data?.data?.status) {
            window.open(response?.data?.data?.url_csv, "Download");
          } else {
            alert("gagal download");
          }
          setLoadingDownload(false);
        })
        .catch(function (error) {
          alert("gagal download");
          setLoadingDownload(false);
        });
    } catch (error) {}
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var strDate = ("0" + d.getDate()).slice(-2);
    var strMonth = ("0" + (d.getMonth() + 1)).slice(-2);
    var strYear = d.getFullYear();

    var seconds = ("0" + d.getSeconds()).slice(-2);
    var minutes = ("0" + d.getMinutes()).slice(-2);
    var hour = ("0" + d.getHours()).slice(-2);

    var formattedDate = `${strYear}-${strMonth}-${strDate} ${hour}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const handleInputChange = (data) => {
    if (data.currentTarget.name === "date_start") {
      setDateStart(data.currentTarget.value);
    } else {
      setDateEnd(data.currentTarget.value);
    }
  };

  return (
    <>
      <div className="p-2 md:p-5 ">
        <div className="flex flex-col md:flex-row justify-between items-start mb-2">
          <div className="flex flex-col justify-start items-start">
            <h1 className="mb-1 text-sm">
              Report:{" "}
              <span className="font-bold ">
                {dateStart} - {dateEnd}
              </span>
            </h1>
            <span className="text-sm">
              Total: <strong>{tbody.length}</strong> Data,{" "}({" "} 
              <strong className="text-green-700">{totEmailTerkirim}</strong> Email Terkirim,{" "}
              <strong className="text-red-700">{totEmailGagal}</strong> Email Gagal Terkirm,{" "}
              <strong className="text-orange-500">{totInputBiodata}</strong> Hanya Input Biodata {" "}){" "}
            </span>
          </div>
          <div className="flex flex-col w-full md:max-w-max md:flex-row mt-4 md:mt-0 items-center md:space-x-2">
            <div className="flex w-full justify-between">
              <input
                name="date_start"
                value={dateStart || formatDate(new Date())}
                onChange={(e) => handleInputChange(e)}
                className="border rounded-lg py-1 px-4  text-sm"
                type="date"
              />
              <div className="mx-2">-</div>
              <input
                name="date_end"
                value={dateEnd || formatDate(new Date())}
                onChange={(e) => handleInputChange(e)}
                className="border rounded-lg py-1 px-4  text-sm"
                type="date"
              />
            </div>
            <button
              onClick={() => getData()}
              className="border rounded-lg py-1 px-4 text-sm mt-2 w-full md:max-w-max md:mt-0"
            >
              Cari Data
            </button>
          </div>
        </div>
        <hr className="mt-2 mb-2" />
        <div className="flex justify-between items-center">
          <div className="space-x-2">
            {/* <button className="border rounded-lg py-1 px-4  text-sm">
              Overview
            </button> */}
            <button className="border rounded-lg py-1 px-4  text-sm">
              Detail
            </button>
          </div>
          <div className="space-x-2">
            {!isEmpty && (
              <>
                <button
                  onClick={() => handleDownload()}
                  className="border rounded-lg py-1 px-4  text-sm"
                >
                  Download
                </button>
                <button
                  onClick={() => getDataDetail()}
                  className="border rounded-lg py-1 px-4  text-sm"
                >
                  Refresh
                </button>
              </>
            )}
          </div>
        </div>

        <hr className="mt-2 mb-2" />

        <div className="flex flex-col bg-white shadow-lg border rounded-lg">
          {loading && (
            <div className="p-4 flex justify-center items-center">
              <div className="font-bold">Harap tunggu, proses data...</div>
            </div>
          )}

          {!loading && isEmpty && (
            <div className="p-4 flex justify-center items-center">
              <div className="font-bold">Tidak ada data...</div>
            </div>
          )}

          {!loading && !isEmpty && (
            <div className="overflow-x-auto max-h-[75vh]">
              <table className="w-full">
                <thead className="border-b bg-gray-300">
                  <tr>
                    {thead.map((item, key) => {
                      return (
                        <th
                          key={key}
                          scope="col"
                          className="font-bold text-sm text-gray-900 max-w-ma px-2 py-4 text-left"
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tbody.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        className={`border-b ${
                          key % 2 == 0 ? "bg-white" : "bg-gray-100"
                        }`}
                      >
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.id}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.nama}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.usia}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.phone}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.email}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.jawaban_quiz}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.result_quiz}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <a
                            href={item.url_image}
                            target="_blank"
                            className="underline cursor-pointer"
                          >
                            Link Image
                          </a>
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <span
                            className={`font-bold ${
                              item.status === "INPUT_BIODATA"
                                ? "text-orange-600"
                                : item.status === "EMAIL_TERKIRIM"
                                ? "text-green-600"
                                : "text-red-600"
                            }`}
                          >
                            {item.status}
                          </span>
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {formatDate(item.created_date)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Report;
