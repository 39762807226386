import { useEffect, useState } from "react";

const Countdown = ({ handleCapture, time }) => {
  const [counter, setCounter] = useState(time);

  useEffect(() => {
    if (counter == 0) {
      setCounter("");
      handleCapture();
    }

    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (time !== 0) {
      setCounter(time);
    }
  }, [time]);

  return (
    <>
      <div className="font-bold text-white text-[300px] w-full h-full flex justify-center items-center overflow-hidden">{counter !== 0 ? counter : ''}</div>
    </>
  );
};

export default Countdown;
