import Photobooth from "./photobooth";
import { Route, Routes } from "react-router";
import Report from "./Report";
import PageAPIKey from "./PageAPIKey";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Photobooth />} />
        <Route path="/report" element={<Report />} />
        <Route path="/cekapikey" element={<PageAPIKey />} />
      </Routes>
    </>
  );
}

export default App;
