import { useRef, useState } from "react";

const FramePhoto = (props) => {
  const bgRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onLoadBg = () => {
    if (bgRef?.current?.width) {
      setWidth(bgRef.current.width);
    }
    if (bgRef?.current?.height) {
      setHeight(bgRef.current.height);
    }
  };
  return (
    <div className={`relative text-white overflow-hidden w-full h-full`}>
      {/* <span className="absolute z-40 top-0 right-0 bg-black text-white text-2xl font-bold">
        {width} : {height}
      </span> */}

      <div className="absolute z-10 top-1/2  -translate-y-[43%] left-1/2 -translate-x-1/2 flex justify-center  w-[480px] h-[640px]  rounded-3xl">
        <img
          ref={bgRef}
          src={props.imgBg}
          className="object-center w-full h-full  object-cover  rounded-3xl"
          alt=""
          onLoad={() => onLoadBg()}
        />
      </div>
      {width !== 0 && height !== 0 && (
        <div
          className="absolute z-10 top-1/2  -translate-y-[43%] left-1/2 -translate-x-1/2 flex justify-center  w-[480px] h-[640px]  rounded-3xl"
          style={{ width: width, height: height }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default FramePhoto;
