import ButtonPrimary from "../primary.button";
import imgBg from "../../images/screen_home.jpg";
import imgBtnStart from "../../images/button_porsche.png";
import imgBtnTOC from "../../images/btn_toc.png";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Content from "./content";

const ScreenHome = (props) => {
  return (
    <Content imgBg={imgBg}>
      <div className="absolute w-[30%] left-1/2 -translate-x-1/2 bottom-1/3 translate-y-1/2 flex flex-col justify-center items-center">
        <img
          src={imgBtnStart}
          onClick={() => props.handleChangeStep()}
          className="animate-pulse cursor-pointer w-full h-auto object-contain"
          alt="btn start"
        />
      </div>
    </Content>
  );
};

export default ScreenHome;
