import ButtonPrimary from "../primary.button";
import { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
import BounceLoader from "react-spinners/BounceLoader";
import Countdown from "../countdown";
import axios from "axios";
import Content from "./content";
import imgBgThankyou from "../../images/screen_thankyou.jpg";
import imgFrameBlack from "../../images/frame/bg-black.jpg";
import imgBg1 from "../../images/frame/bg-1.jpg";
import imgFront1 from "../../images/frame/mobil-1.png";
import imgScreen from "../../images/screen_frame_photo.jpg";
import imgBtnStart from "../../images/button_camera.png";
import imgBtnRetake from "../../images/btn_retake.png";
import imgBtnSave from "../../images/btn_save.png";

import * as htmlToImage from "html-to-image";
import imgBtnPhoto from "../../images/btn-photo.png";
import FramePhoto from "./framePhoto";

const ScreenPhoto = (props) => {
  const videoConstraints = {
    width: 4096,
    height: 2160,
    aspectRatio: 0.6666666667,
    facingMode: "user",
  };

  const [step, setStep] = useState("camera");
  const [onCountdown, setOnCountdown] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageBg, setImageBg] = useState(imgFrameBlack);
  const [showFrame, setShowFrame] = useState(false);
  const [timeCountdown, setTimeCountdown] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const refPhoto = useRef(null);
  const webcamRef = useRef(null);

  const resetCamera = () => {
    setIsError(false);
    setInfoMessage("");
    setStep("camera");
    setImageSrc(null);
    setIsLoading(false);
  };

  const handleChangeStep = (val) => {
    setStep(val);
  };

  const handleChangeBg = (val) => {
    setImageBg(val);
  };

  const startCountdown = () => {
    if (step !== "camera") setStep("camera");
    setTimeCountdown(5);
    setOnCountdown(true);
  };

  const handleCapture = () => {
    setImageBg(imgBg1);
    setImageSrc(webcamRef.current.getScreenshot());
    setStep("captured");
    setOnCountdown(false);
    setTimeCountdown(0);
  };

  useEffect(() => {
    if (
      imageSrc !== null &&
      !imageSrc.includes(process.env.REACT_APP_API_URL)
    ) {
      //get api key
      getApiKey();
      //prosesRemoveBG();
    }
  }, [imageSrc]);

  const getApiKey = () => {
    setIsLoading(true);
    setInfoMessage("Please wait, processing photo.");
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event/getApiKey", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          prosesRemoveBG(response.data.data[0].api_key);
        })
        .catch(function (error) {
          setError("ER_005");
        });
    } catch (error) {}
  };

  const setError = (codeError) => {
    // code error
    // ER_001 => prosesRemoveBG
    // ER_002 => sendEmail
    // ER_003 => submitPhoto
    // ER_005 => gagal ambil key

    setIsLoading(true);
    setIsError(true);
    setInfoMessage(
      `sorry there seems to be an error, please try again or contact the operator. [CODE: ${codeError}]`
    );
  };

  const prosesRemoveBG = (apiKey) => {
    setIsLoading(true);
    setInfoMessage("Please wait, processing photo.");

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        var fd = new FormData();

        fd.append("image", blob);
        fd.append("api_key", apiKey);
        axios
          .post(
            process.env.REACT_APP_API_URL + "photo/removeWithRapidAPI",
            fd,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status) {
              setImageSrc(
                process.env.REACT_APP_API_URL +
                  "photo_remove_bg/" +
                  response?.data?.image
              );

              props.handleSetUser({ image_name_remove: response?.data?.image });
            } else {
              setError("ER_001");
            }
          })
          .catch(function (error) {
            setError("ER_001");
          });
      })
      .catch((error) => {
        setError("ER_001");
      });
  };

  const sendEmail = (imagePath) => {
    let payload = {
      ...props.user,
      image_path: imagePath,
    };

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "email/sendEmail", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data?.status) {
            setIsLoading(false);
            handleChangeStep("thankyou");

            setTimeout(() => {
              props.handleChangeStep("home");
            }, 5000);
          } else {
            setError("ER_003");
          }
        })
        .catch(function (error) {
          setError("ER_003");
        });
    } catch (error) {
      setError("ER_003");
    }
  };

  const deletePhoto = () => {
    let payload = {
      image_name_remove: props.user.image_name_remove,
    };

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "email/deletePhoto", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {})
        .catch(function (error) {});
    } catch (error) {}
  };

  const submitPhoto = () => {
    setIsLoading(true);
    setInfoMessage("Please wait, processing email.");

    htmlToImage
      .toPng(refPhoto.current)
      .then(function (dataBase64) {
        try {
          fetch(dataBase64)
            .then((res) => res.blob())
            .then((blob) => {
              var fd = new FormData();
              fd.append("image", blob);
              fd.append("email", props?.user?.email);
              axios
                .post(
                  process.env.REACT_APP_API_URL + "email/savePhotoResult",
                  fd,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((response) => {
                  if (response?.data?.status) {
                    sendEmail(response.data.image_path);
                  } else {
                    setError("ER_002");
                  }
                })
                .catch(function (error) {
                  setError("ER_002");
                });
            });
        } catch (error) {}
      })
      .catch(function (error) {
        setError("ER_002");
      });
  };

  const retake = () => {
    startCountdown();
    //deletePhoto();
  };

  return (
    <>
      <Content imgBg={imgScreen}>
        <FramePhoto imgBg={imageBg}>
          {step === "camera" && (
            <>
              <div className="absolute z-30 top-0 left-0 flex justify-center items-center w-full h-full">
                <img
                  src={imgFront1}
                  className="object-center w-auto h-full object-contain"
                  alt=""
                />
              </div>
              <div className="absolute z-40 w-full h-full bg-transparent">
                {onCountdown && (
                  <Countdown
                    time={timeCountdown}
                    handleCapture={() => handleCapture()}
                  />
                )}

                {!onCountdown && (
                  <div className="absolute w-[60px] left-1/2 -translate-x-1/2 bottom-[5%] flex flex-col justify-center items-center">
                    <img
                      src={imgBtnStart}
                      onClick={() => startCountdown()}
                      className="cursor-pointer w-full h-auto object-contain"
                      alt="btn start"
                    />
                  </div>
                )}
              </div>
              <div id="webcam">
                <Webcam
                  audio={false}
                  height={"100%"}
                  width={"100%"}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  imageSmoothing={true}
                  mirrored
                />
              </div>
            </>
          )}

          {step === "captured" && (
            <>
              <div>
                {imageSrc !== null && (
                  <div ref={refPhoto} className="w-full h-full">
                    
                    <div className="relative z-20 w-full h-full">
                      <img
                        src={imageSrc}
                        className={`w-full h-full object-cover `}
                      />
                    </div>
                    <div className="absolute z-30 top-0 left-0 flex justify-center items-center w-full h-full">
                      <img
                        src={imgFront1}
                        className="object-center w-auto h-full object-contain"
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>

              {isLoading && (
                <div className="top-0 left-0 bg-[#000000e0] flex-col absolute z-50 w-full h-full flex justify-center items-center">
                  <BounceLoader color="#FFFFFF" />
                  <div className="text-white mt-4 w-1/2 mb-10 text-2xl text-center flex justify-center items-center">
                    {infoMessage}
                  </div>
                  {isError && (
                    <ButtonPrimary onClick={() => resetCamera()}>
                      OK
                    </ButtonPrimary>
                  )}
                </div>
              )}

              {!isLoading && (
                // <div className="absolute w-full z-30 bottom-5 flex justify-center items-center space-x-4">
                //   <ButtonPrimary onClick={() => setShowFrame(true)}>
                //       PILIH BACKGROUND
                //     </ButtonPrimary>
                //   <ButtonPrimary onClick={() => retake()}>RETAKE</ButtonPrimary>
                //   <ButtonPrimary onClick={() => submitPhoto()}>
                //       NEXT
                //     </ButtonPrimary>
                // </div>

                <div className="z-30 absolute w-full bottom-[5%] flex flex-col justify-center items-center">
                  <div className="flex relative w-full justify-center items-center space-x-4">
                    <img
                      src={imgBtnSave}
                      onClick={() => props.handleChangeStep()}
                      className="flex cursor-pointer w-[120px] h-auto object-contain"
                      alt="btn"
                    />
                    <img
                      src={imgBtnRetake}
                      onClick={() => retake()}
                      className="flex cursor-pointer w-[120px] h-auto object-contain"
                      alt="btn"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </FramePhoto>
      </Content>
    </>
  );
};

export default ScreenPhoto;
