import ButtonPrimary from "../primary.button";
import imgBg from "../../images/screen_flow.jpg";
import imgBtnStart from "../../images/btn_start.png";
import imgBtnTOC from "../../images/btn_toc.png";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Content from "./content";

const ScreenFlow = (props) => {
  return (
    <Content imgBg={imgBg}>
      <div className="absolute w-[16%] left-1/2 -translate-x-1/2 bottom-[12%] flex flex-col justify-center items-center">
        <img
          src={imgBtnStart}
          onClick={() => props.handleChangeStep()}
          className="animate-pulse cursor-pointer w-full h-auto object-contain"
          alt="btn start"
        />
      </div>
    </Content>
  );
};

export default ScreenFlow;
