import ButtonPrimary from "../primary.button";
import imgBg from "../../images/screen_car.jpg";
import imgBtnArrowRight from "../../images/arrow_right.png";
import imgBtnArrowLeft from "../../images/arrow_left.png";
import imgMobil1 from "../../images/mobil1.png";
import imgMobil2 from "../../images/mobil2.png";
import imgMobil3 from "../../images/mobil3.png";
import imgMobil4 from "../../images/mobil4.png";
import imgMobil5 from "../../images/mobil5.png";
import imgBtnTOC from "../../images/btn_toc.png";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Content from "./content";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import imgBtnStart from "../../images/btn_start.png";

const SliderPhoto = (props) => {
  const [details, setDetails] = useState(null);
  const [activeIdx, setActiveIdx] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    detailsChanged(s) {
      setActiveIdx(s?.track?.details?.rel);
      setDetails(s.track.details);
    },
    breakpoints: {
      "(min-width: 400px)": {
        slides: { origin: "center", perView: 1.7, spacing: -100 },
      },
    },
  });

  function scaleStyle(idx) {
    if (!details) return {};
    const slide = details.slides[idx];
    const scale_size = 0.7;
    const scale = 1 - (scale_size - scale_size * slide.portion);
    return {
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
    };
  }

  const nextSlide = () => {
    instanceRef.current?.next();
  };

  const prevSlide = () => {
    instanceRef.current?.prev();
  };

  return (
    <>
      <div className="relative h-[35vh] w-full">
        <div ref={sliderRef} className="keen-slider zoom-out h-full w-full">
          {props?.list?.map((item, idx) => (
            <div key={idx} className="keen-slider__slide zoom-out__slide">
              <div
                className="box flex justify-center items-center"
                style={scaleStyle(idx)}
              >
                <div
                  className={`card ${
                    activeIdx === idx ? "scale-100" : "scale-50"
                  } object-contain transition-all absolute`}
                >
                  <img src={item?.img} className="object-contain" alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex space-x-10 justify-center">
        <img
          src={imgBtnArrowLeft}
          onClick={() => prevSlide()}
          className="w-[20%] cursor-pointer h-auto object-contain"
          alt="btn start"
        />
        <img
          src={imgBtnArrowRight}
          onClick={() => nextSlide()}
          className="w-[20%] cursor-pointer h-auto object-contain"
          alt="btn start"
        />
      </div>
    </>
  );
};

const ScreenCar = (props) => {
  const list = [
    {
      img: imgMobil1,
    },
    {
      img: imgMobil2,
    },
    {
      img: imgMobil3,
    },
    {
      img: imgMobil4,
    },
    {
      img: imgMobil5,
    },
  ];

  return (
    <Content imgBg={imgBg}>
      <div className="absolute  w-full left-1/2 -translate-x-1/2 bottom-[25%] flex flex-col justify-center items-center">
        <SliderPhoto list={list} />
      </div>
      <div className="absolute w-[15%] left-1/2 -translate-x-1/2 bottom-[10%] flex flex-col justify-center items-center">
        <img
          src={imgBtnStart}
          onClick={() => props.handleChangeStep()}
          className="animate-pulse cursor-pointer w-full h-auto object-contain"
          alt="btn start"
        />
      </div>
    </Content>
  );
};

export default ScreenCar;
