import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function PageAPIKey() {
  const [thead, setThead] = useState([]);
  const [tbody, setTbody] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [currTotalCall, setCurrTotalCall] = useState(0);
  const [totalCall, setTotalCall] = useState(0);
  const [sisaCall, setSisaCall] = useState(0);
  const maksCall = 23;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getDataDetail();
  };

  const getDataDetail = () => {
    setLoading(true);

    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event/getAllApiKey", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.data.length === 0) {
            setIsEmpty(true);
            setThead([]);
            setTbody([]);
          } else {
            setThead(Object.keys(response.data.data[0]));
            setTbody(response.data.data);
            setIsEmpty(false);

            let totCall = response.data.data.length * maksCall;
            setTotalCall(totCall);

            let curC = response.data.data.reduce(getSum, 0);
            function getSum(total, curr) {
              return total + curr.total_call;
            }
            setCurrTotalCall(curC);

            setSisaCall(totCall - curC);
          }

          setLoading(false);
          //console.log(response.data.data);
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {}
  };

  return (
    <>
      <div className="p-2 md:p-5 ">
        <div>
          Total Call : <strong> {currTotalCall}</strong> dari{" "}
          <strong>
            {totalCall} ({tbody.length} API KEY)
          </strong>

          -  sisa <strong className="text-red-600 font-bold">{sisaCall}</strong>
        </div>

        <br />
        <div className="flex flex-col bg-white shadow-lg border rounded-lg">
          {loading && (
            <div className="p-4 flex justify-center items-center">
              <div className="font-bold">Harap tunggu, proses data...</div>
            </div>
          )}

          {!loading && isEmpty && (
            <div className="p-4 flex justify-center items-center">
              <div className="font-bold">Tidak ada data...</div>
            </div>
          )}

          {!loading && !isEmpty && (
            <div className="overflow-x-auto max-h-[75vh]">
              <table className="w-full">
                <thead className="border-b bg-gray-300">
                  <tr>
                    {thead.map((item, key) => {
                      return (
                        <th
                          key={key}
                          scope="col"
                          className="font-bold text-sm text-gray-900 max-w-ma px-2 py-4 text-left"
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tbody.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        className={`border-b ${
                          key % 2 == 0 ? "bg-white" : "bg-gray-100"
                        }`}
                      >
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.id}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.api_key}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <span
                            className={`font-bold ${
                              item.total_call >= maksCall
                                ? "text-orange-600"
                                : "text-green-600"
                            }`}
                          >
                            {item.total_call}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PageAPIKey;
