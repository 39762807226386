import "./App.css";
import { useEffect, useState } from "react";
import ScreenEmail from "./components/screen/email.screen";
import ScreenHome from "./components/screen/home.screen";
import ScreenPhoto from "./components/screen/photo.screen";
import ScreenJourney from "./components/screen/journey.screen";
import ScreenPersonality from "./components/screen/personality.screen";
import ScreenTOC from "./components/screen/toc.screen";
import axios from "axios";
import ScreenFlow from "./components/screen/flow.screen";
import ScreenCar from "./components/screen/carscreen";
import ScreenFinish from "./components/screen/finish.screen";

function Photobooth() {
  const [user, setUser] = useState(null);
  const [step, setStep] = useState("home");

  useEffect(() => {
    console.log(user);
  }, [user]);

  const handleChangeStep = (val) => {
    setStep(val);
  };

  const handleShowToc = () => {
    setStep("toc");
  };

  const handleSetUser = (val) => {
    setUser({
      ...user,
      ...val,
    });
  };

  const handleCheckUser = () => {
    console.log("USER");
    console.log(user);
    console.log("==========");
  };

  const testPost = () => {
    let payload = {
      id: 34,
      nama: "resmaya",
      usia: "32",
      phone: "081214149548",
      email: "dickyjayaumbara@gmail.com",
      answer: "A,A,C,A,B,B",
      personality: "EXPLORER",
      image_path:
        "https://api.eventqiu.com/photobooth/" +
        `result_photo/26-12-2022/dickyjayaumbara@gmail.com_1672033256813.png`,
    };

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "email/sendEmail", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          debugger;
        })
        .catch(function (error) {
          debugger;
        });
    } catch (error) {
      debugger;
    }
  };

  return (
    // <div className="bg-[#feefdc] w-screen">
    <div className="relative bg-black w-screen h-screen">
      {/* <div
        onClick={() => testPost()}
        className="z-50 text-white absolute top-0 left-0 px-10 py-5 font-bold text-whtie bg-primary"
      >
        POST USER
      </div> */}

      {/* STEP HOME SCREEN */}
      {step === "home" && (
        <ScreenHome handleChangeStep={() => handleChangeStep("flow")} />
      )}

      {/* STEP FLOW SCREEN */}
      {step === "flow" && (
        <ScreenFlow handleChangeStep={() => handleChangeStep("car")} />
      )}

      {/* STEP CAR SCREEN */}
      {step === "car" && (
        <ScreenCar handleChangeStep={() => handleChangeStep("photo")} />
      )}

      {/* STEP PHOTO SCREEN */}
      {step === "photo" && (
        <ScreenPhoto
          user={user}
          handleSetUser={(val) => handleSetUser(val)}
          handleChangeStep={() => handleChangeStep("email")}
        />
      )}

      {/* STEP EMAIL SCREEN */}
      {step === "email" && (
        <ScreenEmail
          user={user}
          handleSetUser={(val) => handleSetUser(val)}
          handleChangeStep={() => handleChangeStep("finish")}
        />
      )}

      {/* STEP FLOW SCREEN */}
      {step === "finish" && (
        <ScreenFinish handleChangeStep={() => handleChangeStep("home")} />
      )}
    </div>
  );

  //   <img
  //   ref={imgRef}
  //   src={process.env.PUBLIC_URL + "images/frame-video.png"}
  //   className={`w-full object-contain absolute m-auto`}
  // />
}

export default Photobooth;
