import ButtonPrimary from "../primary.button";
import imgBg from "../../images/screen_finish.jpg";
import imgBtnStart from "../../images/btn_start.png";
import imgBtnTOC from "../../images/btn_toc.png";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Content from "./content";

const ScreenFinish = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.handleChangeStep();
    }, 5000);
  }, []);

  return <Content imgBg={imgBg}></Content>;
};

export default ScreenFinish;
