import ButtonPrimary from "../primary.button";
import { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
import VirtualKeyboard from "../virtualKeyboard";
import { useForm } from "react-hook-form";
import axios from "axios";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import imgBg from "../../images/screen_email.jpg";
import imgForm from "../../images/img-form.png";
import Content from "./content";
import imgBtnNext from "../../images/btn_next_2.png";
import { BounceLoader } from "react-spinners";
import imgBtnStart from "../../images/btn_send.png";

const ScreenEmail = (props) => {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setInputName("email");
  }, [])

  const onSubmit = (data) => {
    postBiodata(data);
  };

  const postBiodata = (data) => {
    setIsLoading(true);
    setIsError(false);
    setInfoMessage("Please wait, processing data.");

    let payload = {
      ...props.user,
      ...data,
      status: "INPUT_BIODATA",
    };

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "event/postBiodata", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data?.status) {
            setIsLoading(false);
            setIsError(false);

            props.handleSetUser({
              ...data,
              id: response.data.insertId,
            });

            props.handleChangeStep();
          } else {
            setIsLoading(true);
            setIsError(false);
            setError();
          }
        })
        .catch(function (error) {
          setError();
        });
    } catch (error) {
      setError();
    }
  };

  const setError = (codeError) => {
    setIsLoading(true);
    setIsError(true);
    setInfoMessage(
      `sorry there seems to be an error, please try again or contact the operator. [CODE: 004]`
    );
  };

  const errorOk = () => {
    setIsLoading(false);
    setIsError(false);
  };

  const [inputs, setInputs] = useState({});
  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("default");
  const keyboard = useRef();

  const onChangeAll = (inputs) => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs });
    setValue(inputName, inputs[inputName]);
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;

    setValue(inputName, inputVal);

    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (inputName) => {
    return inputs[inputName] || "";
  };

  return (
    <Content imgBg={imgBg}>
      {isLoading && (
        <div className="top-0 left-0 bg-[#000000e0] flex-col absolute z-50 w-full h-full flex justify-center items-center">
          <BounceLoader color="#FFFFFF" />
          <div className="text-white mt-4 w-1/2 mb-10 text-2xl text-center flex justify-center items-center">
            {infoMessage}
          </div>
          {isError && (
            <ButtonPrimary onClick={() => errorOk()}>OK</ButtonPrimary>
          )}
        </div>
      )}

      <div className="flex w-full justify-center items-center h-screen">
        {/* <div>
          <img src={imgForm} className="w-[230px]" />
        </div> */}
        <form
          className="relative flex flex-col justify-start items-start bg-transparent rounded-lg w-[620px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* NAMA */}
          {/* <input
            id="nama"
            value={getInputValue("nama")}
            onFocus={() => {
              setInputName("nama");
              setShowKeyboard(true);
            }}
            type={"text"}
            className={`placeholder:text-gray-600 text-black bg-transparent border-2 px-5 py-3 rounded-xl w-full focus:outline-none ${
              errors.nama
                ? "border-red-500"
                : inputName === "nama"
                ? "border-[#f07384] border-3"
                : "border-[#f07384]"
            }`}
            placeholder="Ketik nama anda"
            {...register("nama", { required: true })}
          /> */}

          {/* USIA */}
          {/* <input
            id="usia"
            value={getInputValue("usia")}
            onFocus={() => {
              setInputName("usia");
              setShowKeyboard(true);
            }}
            type={"text"}
            className={`placeholder:text-gray-600 text-black bg-transparent border-2 px-5 py-3 rounded-xl w-full focus:outline-none ${
              errors.usia
                ? "border-red-500"
                : inputName === "usia"
                ? "border-[#f07384] border-3"
                : "border-[#f07384]"
            }`}
            placeholder="Ketik usia anda"
            {...register("usia", { required: true })}
          /> */}

          <div className="mt-9"></div>

          {/* phone */}
          {/* <input
            id="phone"
            value={getInputValue("phone")}
            onFocus={() => {
              setInputName("phone");
              setShowKeyboard(true);
            }}
            type="text"
            className={`placeholder:text-gray-600 text-black bg-transparent border-2 px-5 py-3 rounded-xl w-full focus:outline-none ${
              errors.phone
                ? "border-red-500"
                : inputName === "phone"
                ? "border-[#f07384] border-3"
                : "border-[#f07384]"
            }`}
            placeholder="Ketik telp anda"
            {...register("phone", { required: true })}
          /> */}

          <div className="mt-9"></div>

          {/* EMAIL */}
          <input
            id="email"
            value={getInputValue("email")}
            onFocus={() => {
              setInputName("email");
              setShowKeyboard(true);
            }}
            type={"email"}
            className={`bg-white placeholder:text-gray-600 text-black bg-transparent border-2 px-5 py-3 rounded-xl w-full focus:outline-none ${
              errors.email
                ? "border-red-500"
                : inputName === "email"
                ? "border-white border-3"
                : "border-white"
            }`}
            placeholder="E-MAIl"
            {...register("email", { required: true })}
          />
          <div className="flex w-full justify-center items-center my-10">
            <img
              src={imgBtnStart}
              onClick={() => props.handleChangeStep()}
              className="cursor-pointer h-[45px] object-contain"
              alt="btn start"
            />
          </div>
          <div className={`w-full flex justify-center items-center `}>
            <div className="relative w-full h-auto text-black bg-balck border border-white rounded-2xl p-4">
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                inputName={inputName}
                layoutName={layoutName}
                onChangeAll={onChangeAll}
                onKeyPress={onKeyPress}
                layout={{
                  default:
                    inputName === "phone" || inputName === "usia"
                      ? ["1 2 3", "4 5 6", "7 8 9", "0", "{bksp}"]
                      : [
                          "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                          "{tab} q w e r t y u i o p [ ] \\",
                          "{lock} a s d f g h j k l ; ' {enter}",
                          "{shift} z x c v b n m , . / {shift}",
                          ".com @ {space}",
                        ],
                  shift: [
                    "~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M &lt; &gt; ? {shift}",
                    ".com @ {space}",
                  ],
                }}
              />
            </div>
          </div>

          {msgError !== "" && (
            <span class="text-red-500 font-bold w-full text-center mt-5">
              Error : {msgError}
            </span>
          )}
          
        </form>
      </div>
    </Content>
  );
};

export default ScreenEmail;
